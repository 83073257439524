import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
// import Fab from '@material-ui/core/Fab';
// import AddIcon from '@material-ui/icons/Add';
import "./cardscalculadoras.scss";
import arrow from "../../assets/img/arrow.svg";
import movietickets from "../../assets/img/movietickets.svg";
import groceries from "../../assets/img/grocery.svg";
import "./gastostotales.scss";
import styled from 'styled-components'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import NumberFormat from 'react-number-format';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
const convertir = require('numero-a-letras');

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'space-evenly',
      flexWrap: 'wrap',
      '& label.Mui-focused': {
        color: '#049AB3',
      },
      '& .MuiFilledInput-underline:after': {
        borderBottomColor: '#049AB3',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      }
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 220,
    },
    margin: {
      position: 'relative',
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: '25ch',
    },
    addButton: {
      position: 'absolute',
      top: '50%',
      right: '10px',
      backgroundColor: '#049AB3',
      color: 'black',
    },
    labelColor: {
      color: "#049AB3"
    }
  }));

const Card = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 351px;
    box-shadow: 0px -5.33435px 10.6687px rgba(159, 159, 159, 0.25);
    border-radius: 32.0061px;
    margin: 80px 0 0 0;
`

const CardImage = styled.img`
    border-radius: 32px 32px 0 0;
    width: 100%
`

const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 32px;
    background: white;
    margin-top: -60px;
    padding: 10px 15px 50px 15px;
`

const Info = styled.p`
    margin: 10px 0 50px 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #333333;
`

const CardTitle = styled.h3`
    margin: 0 0 10px 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #1A154C;
    span{
        display: block;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #1A154C;
    }
`
const CardSubtitle = styled.h3`
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    color: #049AB3;
    width: 80%;
    margin: 0 auto;
`

const Main = styled.main`
    padding: 0;
`

const SelectsContainer = styled.div`
    display: flex;
    margin: 20px 0;
`

const Cotizador = ({ scroll }) => {

    const classes = useStyles();

    const handleGender = (event) => {
        setGender(event.target.value);
    };

    const handleAge = (event) => {
        setAge(event.target.value);
    };

    const [basicos, setBasicos] = useState(0);
    const [manutencion, setManutencion] = useState(0);
    const [otros, setOtros] = useState(0);

    React.useEffect(() => {
        setTotalExpenses(basicos + manutencion + otros)
    }, [basicos, manutencion, otros])
    
    const [totalExpenses, setTotalExpenses] = useState(0);

    const [gender, setGender] = useState(null);
    const [age, setAge] = useState(null);

    const [monthly, setMonthly] = useState(0);

    const [equiv, setEquiv] = useState({
        cine: {
            cantidad: '',
            s: ''
        },
        super: {
            cantidad: '',
            s: ''
        }
    })

    React.useEffect(() => {
        const cine = monthly / 200
        const supermercado = monthly / 500

        setEquiv({
            ...equiv,
            ['cine']: {
                ['cantidad']: convertir.NumerosALetras(Math.floor(cine)) === 'Cero' ? '' : convertir.NumerosALetras(Math.floor(cine)),
                ['s']: (Math.floor(cine)) === 1 ? '' : 's'
            },
            ['super']: {
                ['cantidad']: convertir.NumerosALetras(Math.floor(supermercado)) === 'Cero' ? '' : convertir.NumerosALetras(Math.floor(supermercado)),
                ['s']: (Math.floor(supermercado)) === 1 ? '' : 's'
            }

        })
    }, [monthly])

    const calculate = () => {
        let newMonthly = totalExpenses * 60;

        console.log(newMonthly);
        switch (true) {
            case (newMonthly <= 1000000):
                newMonthly = 1000000
                setMonthly(list[newMonthly][age][gender])
                break;
            case (newMonthly <= 1500000 && newMonthly >= 1000001):
                newMonthly = 1500000
                setMonthly(list[newMonthly][age][gender])
                break;
            case (newMonthly <= 2000000 && newMonthly >= 1500001):
                newMonthly = 2000000
                setMonthly(list[newMonthly][age][gender])
                break;
            case (newMonthly <= 2500000 && newMonthly >= 2000001):
                newMonthly = 2500000
                setMonthly(list[newMonthly][age][gender])
                break;
            case (newMonthly <= 3000000 && newMonthly >= 2500001):
                newMonthly = 2500000
                setMonthly(list[newMonthly][age][gender])
                break;
            case (newMonthly <= 3500000 && newMonthly >= 3000001):
                newMonthly = 3500000
                setMonthly(list[newMonthly][age][gender])
                break;
            case (newMonthly <= 4000000 && newMonthly >= 3500001):
                newMonthly = 4000000
                setMonthly(list[newMonthly][age][gender])
                break;
            case (newMonthly <= 4500000 && newMonthly >= 4000001):
                newMonthly = 4500000
                setMonthly(list[newMonthly][age][gender])
                break;
            case (newMonthly <= 5000000 && newMonthly >= 4500001):
                newMonthly = 5000000
                setMonthly(list[newMonthly][age][gender])
                break;
            case (newMonthly <= 5500000 && newMonthly >= 5000001):
                newMonthly = 5500000
                setMonthly(list[newMonthly][age][gender])
                break;
            case (newMonthly <= 6000000 && newMonthly >= 5500001):
                newMonthly = 6000000
                setMonthly(list[newMonthly][age][gender])
                break;
            case (newMonthly <= 6500000 && newMonthly >= 6000001):
                newMonthly = 6500000
                setMonthly(list[newMonthly][age][gender])
                break;
            default: 
                break;
        }
    }

    const list = {
        // posición 0 del array es el hombre
        1000000: {
            30: [399, 349],
            35: [399, 349],
            40: [499, 499],
            45: [599, 499],
            50: [799, 599],
            55: [1099, 899],
            60: [1449, 1099],
        },
        1500000: {
            30: [449, 399],
            35: [499, 449],
            40: [649, 499],
            45: [799, 599],
            50: [1099, 799],
            55: [1499, 1199],
            60: [2099, 1599],
        },
        2000000: {
            30: [549, 449],
            35: [599, 499],
            40: [799, 599],
            45: [999, 799],
            50: [1399, 999],
            55: [1899, 1499],
            60: [2699, 2049],
        },
        2500000: {
            30: [649, 499],
            35: [699, 549],
            40: [899, 699],
            45: [1199, 899],
            50: [1699, 1199],
            55: [2399, 1799],
            60: [3299, 2499],
        },
        3000000: {
            30: [699, 549],
            35: [799, 599],
            40: [999, 799],
            45: [1399, 999],
            50: [1899, 1399],
            55: [2799, 2099],
            60: [3899, 2899],
        },
        3500000: {
            30: [799, 599],
            35: [899, 699],
            40: [1199, 899],
            45: [1551, 1098],
            50: [2181, 1557],
            55: [3158, 2345],
            60: [4408, 3347],
        },
        4000000: {
            30: [849, 699],
            35: [999, 749],
            40: [1299, 999],
            45: [1750, 1231],
            50: [2470, 1757],
            55: [3586, 2657],
            60: [5015, 3802],
        },
        4500000: {
            30: [949, 749],
            35: [1099, 849],
            40: [1449, 1099],
            45: [1948, 1365],
            50: [2758, 1956],
            55: [4014, 2969],
            60: [5621, 4257],
        },
        5000000: {
            30: [999, 799],
            35: [1199, 899],
            40: [1599, 1199],
            45: [2147, 1499],
            50: [3047, 2158],
            55: [4442, 3281],
            60: [6228, 4712],
        },
        5500000: {
            30: [1099, 849],
            35: [1299, 999],
            40: [1799, 1299],
            45: [2345, 1632],
            50: [3335, 2355],
            55: [4869, 3592],
            60: [6835, 5166],
        },
        6000000: {
            30: [1199, 899],
            35: [1399, 1049],
            40: [1899, 1399],
            45: [2543, 1766],
            50: [3623, 2554],
            55: [5297, 3904],
            60: [7441, 5621],
        },
        6500000: {
            30: [1249, 999],
            35: [1499, 1099],
            40: [1999, 1499],
            45: [2724, 1882],
            50: [3894, 2736],
            55: [5708, 4199],
            60: [8030, 6059],
        },
        7000000: {
            30: [1349, 1049],
            35: [1599, 1199],
            40: [2199, 1599],
            45: [2921, 2014],
            50: [4181, 2934],
            55: [6134, 4509],
            60: [8636, 6512],
        },
    }
    
    function TypingComponent({ textToType, delay, loop }) {
        const [text, setText] = useState("");
        const [currentIndex, setCurrentIndex] = useState(0);
        React.useEffect(() => {
          if (currentIndex < textToType.length) {
            setTimeout(() => {
              setText(text + textToType[currentIndex]);
              setCurrentIndex(currentIndex + 1);
            }, delay);
          } else if (loop) {
            // reset the text and the index
            setText("");
            setCurrentIndex(0);
          }
        }, [currentIndex, equiv]);
        return <div>{text}</div>;
    }

    return (
        <Main>
            <form className={classes.root} noValidate autoComplete="off">
                <Card>
                    <CardImage src="../static/images/basicos.jpeg" alt="Enroque gastos básicos" />
                    <CardContent>
                        <CardTitle>
                            Gastos básicos
                            <span>MENSUAL</span>
                        </CardTitle>
                        <CardSubtitle>
                            Lo no negociable para los tuyos.
                        </CardSubtitle>
                        <Info>Renta/Hipoteca + Comida + Servicios + Limpieza + Mantenimientos, Gasolina, transporte, deudas</Info>
                        <FormControl fullWidth className={classes.margin} variant="filled">
                            <CurrencyTextField
                                label="Gastos Básicos"
                                variant="filled"
                                value={basicos}
                                currencySymbol="$"
                                outputFormat="number"
                                onChange={(event, value)=> setBasicos(value)}
                                textAlign="left"
                                minimumValue="0"
                                type="text"
                            />
                            {/* <Fab color="primary" aria-label="add" className={classes.addButton}>
                                <AddIcon />
                            </Fab> */}
                        </FormControl>
                    </CardContent>
                </Card>
                <Card>
                    <CardImage src="../static/images/manutencion.jpeg" alt="Enroque gastos manutención" />
                    <CardContent>
                        <CardTitle>
                            Gastos de Manutención 
                            <span>MENSUAL</span>
                        </CardTitle>
                        <CardSubtitle>
                            Aquello que necesita tu familia para estar bien.
                        </CardSubtitle>
                        <Info>Colegiaturas, uniformes, útiles escolares, doctores, medicinas, entretenimiento, regalos.</Info>
                        <FormControl fullWidth className={classes.margin} variant="filled">
                            <CurrencyTextField
                                label="Gastos de Manutención"
                                variant="filled"
                                value={manutencion}
                                currencySymbol="$"
                                outputFormat="number"
                                onChange={(event, value)=> setManutencion(value)}
                                textAlign="left"
                                minimumValue="0"
                            />
                            {/* <Fab color="primary" aria-label="add" className={classes.addButton}>
                                <AddIcon />
                            </Fab> */}
                        </FormControl>
                    </CardContent>
                </Card>
                <Card>
                    <CardImage src="../static/images/otros.jpeg" alt="Enroque otros gastos" />
                    <CardContent>
                        <CardTitle>
                            Otros Gastos
                            <span>MENSUAL</span>
                        </CardTitle>
                        <CardSubtitle>
                            Lo que le gusta a tu familia y les hace bien.
                        </CardSubtitle>
                        <Info>Vacaciones, ropa, mascota, visitas médicas de rutina, deportes, diversión, restaurantes.</Info>
                        <FormControl fullWidth className={classes.margin} variant="filled">
                            <CurrencyTextField
                                label="Otros Gastos"
                                variant="filled"
                                value={otros}
                                currencySymbol="$"
                                outputFormat="number"
                                onChange={(event, value)=> setOtros(value)}
                                textAlign="left"
                                minimumValue="0"
                            />
                            {/* <Fab color="primary" aria-label="add" className={classes.addButton}>
                                <AddIcon />
                            </Fab> */}
                        </FormControl>
                    </CardContent>
                </Card>
            </form>
            <div className="gastos-totales-component">
                <div className="gastos-totales-box">
                    <div className="border-component">
                        <h4 className="gastos-title">GASTOS TOTALES</h4>
                        <div className="result-component"><NumberFormat value={totalExpenses} displayType={'text'} thousandSeparator={true} prefix={'$'} /></div>
                        <p className="parrafo-tiempo">MENSUAL</p>{" "}
                    </div>
                </div>
            </div>
            <div className="cardscalculadoras-component">
                <div className="cards-component first-one">
                    <h3 className="first-text">
                    La cifra mensual que necesitas
                    <span className="blue-light">
                        para brindarle la seguridad a tu familia
                    </span>
                    para cuando no estés:
                    </h3>
                    <form className={classes.root} noValidate autoComplete="off">
                        <SelectsContainer>
                            <FormControl variant="filled" className={classes.formControl}>
                                <InputLabel id="genderLabelId">Selecciona tu sexo</InputLabel>
                                <Select
                                    labelId="genderLabelId"
                                    id="genderSelectId"
                                    value={gender}
                                    onChange={handleGender}
                                >
                                    <MenuItem value="" disabled>
                                        Selecciona tu sexo
                                    </MenuItem>
                                    <MenuItem value={1}>Mujer</MenuItem>
                                    <MenuItem value={0}>Hombre</MenuItem>
                                </Select>
                            </FormControl>
                        </SelectsContainer>
                        <SelectsContainer>
                            <FormControl variant="filled" className={classes.formControl}>
                                <InputLabel id="ageLabelId" style={{
                                    width: "160px",
                                    textAlign: "left",
                                }}>Selecciona tu rango de edad</InputLabel>
                                <Select
                                    labelId="ageLabelId"
                                    id="ageSelectId"
                                    value={age}
                                    onChange={handleAge}
                                >
                                    <MenuItem value="" disabled>
                                        Selecciona tu rango de edad
                                    </MenuItem>
                                    <MenuItem value={30}>30 - 35</MenuItem>
                                    <MenuItem value={35}>35 - 40</MenuItem>
                                    <MenuItem value={40}>40 - 45</MenuItem>
                                    <MenuItem value={45}>45 - 50</MenuItem>
                                    <MenuItem value={50}>50 - 55</MenuItem>
                                    <MenuItem value={55}>55 - 60</MenuItem>
                                    <MenuItem value={60}>60 - 65</MenuItem>
                                </Select>
                            </FormControl>
                        </SelectsContainer>
                    </form>
                    <h5 className="calcular-seguridad">Calcular Seguridad Familiar</h5>
                    <button onClick={calculate}>
                    <p>CALCULAR</p>
                    <img src={arrow} alt={"Arrow Right"} />
                    </button>
                </div>
                <div className="cards-component second-one">
                    <div className="blue-component">
                    {" "}
                    <div className="number-component"><NumberFormat value={monthly} displayType={'text'} thousandSeparator={true} prefix={'$'} /></div>
                    <p className="ab-mensual">MENSUAL ESTIMADO</p>
                    </div>

                    <h5 className="seguridad-compo">
                    La seguridad de tu familia
                    <span className="blueletra"> cuesta poco menos que:</span>
                    </h5>
                    <div className="icons-component">
                    <div className="sfdk tres-compo">
                        <div className="number-sfdk">
                        <TypingComponent textToType={equiv.cine.cantidad} delay={200} loop={false} />
                        </div>
                        <div className="before-img">
                        <img
                            className="imagenrelativa"
                            src={movietickets}
                            alt="Tickets de cine"
                        />
                        </div>
                        <div className="text-complement">
                        entrada{equiv.cine.s} <span className="blueelight">al cine</span>
                        </div>
                    </div>
                    <div className="sfdk dos-compo">
                        {" "}
                        <div className="number-sfdk">
                            <TypingComponent textToType={equiv.super.cantidad} delay={200} loop={false} />
                        </div>
                        <div className="before-img withborder">
                        <img
                            className="imagenrelativa withborder"
                            src={groceries}
                            alt="Tickets de cine"
                        />
                        </div>
                        <div className="text-complement">
                        compra{equiv.super.s} del <span className="blueelight">Supermercado</span>
                        </div>
                    </div>
                    </div>
                    <button onClick={scroll}>
                    <p>CONTACTAR</p>
                    <img src={arrow} alt={"Arrow Right"} />
                    </button>
                </div>
            </div>
        </Main>
    )
}
export default Cotizador