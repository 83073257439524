import React from "react";
import "./header.scss";
import logoheader from "../../assets/img/logoheader.svg";
import logoheadermini from "../../assets/img/logoheadermini.svg";

const Header = () => {
  return (
    <div className="header-container">
      <div className="nav-bar-enroque">
        <a href="/">
          <img
            className="hea-img-title big"
            src={logoheader}
            alt={"Seguros Enroque"}
          />
          <img
            className="hea-img-title mini"
            src={logoheadermini}
            alt={"Seguros Enroque"}
          />
        </a>
      </div>
      <div className="header-enroque">
        <div className="header-box">
          <h3>Como sustento del hogar, ¿Te has preguntado?</h3>
          <h1>
            ¿Qué pasaría con tu familia el día que sufras invalidez o mueras?
          </h1>
          <h4>
            Te ayudamos a calcular el monto de tranquilidad que necesitas para
            dejar protegida a tu familia.{" "}
          </h4>{" "}
        </div>
      </div>
    </div>
  );
};

export default Header;
