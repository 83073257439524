import React from "react";
import "./information.scss";
import gastos from "../../assets/img/gastos.svg";
import prima from "../../assets/img/prima.svg";
import enfermedad from "../../assets/img/enfermedad.svg";

const Information = () => {
  return (
    <div className="component-information">
      <div className="component-image">
        <h3>¡Ten la certeza de acompañarlos Incluso cuando no estés!</h3>
      </div>
      <div className="component-classifications">
        <div className="comp-clasi-white">
          <p>
            {" "}
            No dejes que tu familia se quede sin tu protección, contrata un plan
            de protección familiar que te asegure dejarlos protegidos con todo
            el sustento económico como si tu estuvieras.
          </p>
          <h5>
            Date la oportunidad de dormir más tranquilo con la certeza de poder
            planear con tranquilidad el{" "}
            <span className="inherit-ss">
              futuro que quieres para tu familia.
            </span>
          </h5>
          <div className="components-3cards">
            <div className="card-compo">
              <img
                className="card-img"
                src={gastos}
                alt={"Gastos funerarios"}
              />
              <div className="co-double">
                <h5>Gastos funerarios</h5>
                <div className="hola-cali">
                  {" "}
                  <p>Nos encargamos de los gastos funerarios.</p>
                </div>
              </div>
            </div>
            <div className="card-compo">
              {" "}
              <img className="card-img" src={prima} alt={"Prima asegurada"} />
              <div className="co-double">
                <h5>Prima Asegurada</h5>
                <div className="hola-cali">
                  <p>Tu familia recibe la prima asegurada mensual.</p>
                </div>{" "}
              </div>
            </div>
            <div className="card-compo">
              {" "}
              <img
                className="card-img"
                src={enfermedad}
                alt={"Enfermedad terminal"}
              />
              <div className="co-double">
                <h5>Enfermedad Terminal</h5>
                <div className="hola-cali">
                  <p>
                    Si a caso tienes una enfermedad terminal te adelantamos una
                    parte, para que puedas cumplir un último deseo o cualquier
                    imprevisto.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Information;
