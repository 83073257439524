import React from "react";
import Header from "./components/header/Header";
import Information from "./components/information/Information";
import Footer from "./components/footer/Footer";
import Cotizador from "./components/cotizador/Cotizador";
import Contacto from "./components/contacto/Contacto";
function App() {

  const myRef = React.useRef(null);

  const scroll = () => {
      
      window.scrollTo({
          top: myRef.current.offsetTop - 70,
          behavior: "smooth"
      });
  }
    
  return (
    <>
      <Header />
      <Cotizador scroll={scroll}/>
      <Information />
      <Contacto myRef={myRef} />
      <Footer />

    </>
  );
}

export default App;
