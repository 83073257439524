import React from 'react'
import styled from 'styled-components'
import HubspotForm from 'react-hubspot-form'

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #1A154C;
    padding: 90px 40px;
    box-sizing: border-box;
    div{
        width: 50%;
        @media (max-width: 768px) {
            width: 95%;
        }
        div{
            width: 100%;
            iframe{
                width: 100% !important;
            }
        }
    }

`

const LetUsHelpYou = styled.h2`
    width: 70%;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
    @media (max-width: 768px) {
        width: 90%;
        font-size: 24px;
        line-height: 32px;
    }
    span{   
        color: #049AB3;
    }
`

const FillThisFrom = styled.h4`
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
    @media (max-width: 768px) {
        width: 95%;
        font-size: 20px;
        line-height: 30px;
    }
`

const Contacto = ({ myRef}) => {
    return (
        <FormContainer>
            <LetUsHelpYou ref={myRef}>
                Déjanos ayudarte a cuidar a <span> tu familia</span>, somos expertos en protección.
            </LetUsHelpYou>
            <FillThisFrom>
                Todo empieza llenando este formulario.
            </FillThisFrom>
            <HubspotForm
                portalId='6646757'
                formId='c5afbf12-79cf-4e2c-9750-542cbe3ee04d'
                onSubmit={() => console.log('Submit!')}
                onReady={(form) => console.log('Form ready!')}
                loading={<div>Cargando...</div>}
                style={{
                    width: '100%',
                }}
            />
        </FormContainer>
    )
}

export default Contacto
