import React from "react";
import "./footer.scss";
import whatsappfooter from "../../assets/img/whatsappfooter.svg";
import phonefooter from "../../assets/img/phonefooter.svg";
import logofoooter from "../../assets/img/logofoooter.svg";

const Footer = () => {
  return (
    <div className="footer-white">
      <h4>
        Agenda una cita con nuestro equipo para que te continuen asesorando.{" "}
      </h4>
      <a href="https://api.whatsapp.com/send?phone=++528712639246&text=Hola%20Enroque,%20Quisiera%20contratar%20un%20seguro%20de%20vida,%20m%C3%AD%20nombre%20es">
        <button className="footer-btn">
          <p>
            AGENDAR AHORA{" "}
            <img
              className="fo-svg"
              src={whatsappfooter}
              alt={"Icono whatsapp"}
            />
          </p>
        </button>
      </a>
      <a href="tel:8717275218">
        <button className="footer-btn">
          <p>
            LLAMA AHORA{" "}
            <img className="fo-svg" src={phonefooter} alt={"Icono telefono"} />
          </p>
        </button>
      </a>
      <img className="fo-svg-lo" src={logofoooter} alt={"Seguros Enroque"} />
    </div>
  );
};

export default Footer;
